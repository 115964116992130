<template>
    <div style="padding: 22px 15px; overflow-y: scroll;">
        <Horizontal ref="Horizontal"
        :listType="listType"
        :currentListType="currentListType"
        :historyList="historyList"
        :isShowLastTips="isShowLastTips"
        @getCurrentTypeList="getCurrentTypeList"
         v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>

        <Vertical 
        :listType="listType"
        :currentListType="currentListType"
        :historyList="historyList"
        :isShowLastTips="isShowLastTips"
        @getCurrentTypeList="getCurrentTypeList"
        v-else></Vertical>

    </div>
</template>

<script>
    import Horizontal from '@/components/Index/Exchange/Horizontal/Detail.vue'
    import Vertical from '@/components/Index/Exchange/Vertical/Detail.vue'
    
    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                listType:['余额', '游戏币 '],
                currentListType:0,
                historyList:[],
                isShowLastTips:true
            }
        },
        created() {
            this.getCurrentTypeList(0, true)
        },
        methods: {
            //切换当前列表类型
            getCurrentTypeList(i, isCreated) {
                if(i === this.currentListType && !isCreated) {
                    //点击相同的类型title
                    return
                }
                this.historyList = []
                this.currentListType = i
                if(i === 0) {
                    this.getBalanceChangeHistoryList()
                }else {
                    this.getCoinChangeHistoryList()
                }
            },
            //获取余额列表
            getBalanceChangeHistoryList() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token
                }
                this.$api.exchange.getBalanceChangeHistoryList(requestData).then(res=> {
                    this.historyList = res.data.data
                })
            },
            //获取游戏币变化列表
            getCoinChangeHistoryList() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token
                }
                this.$api.exchange.getCoinChangeHistoryList(requestData).then(res=> {
                    this.historyList = res.data.data
                })
            }
        }
    }
</script>

<style>
</style>