<template>
    <div class="history-box">
        <TopNav style="width: 100%;" title="明细" backPath="ExchangeIndex"></TopNav>
        <div class="list-type-title-box">
            <div class="list-type-title">
                <div class="list-type-title-item" v-for="(item, index) in listType" :key="index" @click="getCurrentTypeList(index)">
                    {{item}}
                </div>
            </div>
            <div class="select-line" :style="{left:currentListType*50+'%'}"></div>
        </div>
        
        <div class="list-box">
            <div class="history-item-box" v-for="(item, index) in historyList" :key="index">
                <!-- 余额列表 -->
                <div class="history-item" v-if="currentListType === 0">
                    <div class="history-item-left">
                        <div class="history-item-title">
                           {{item.reward_type | getRewardType}}{{item.reward_type!==6?'获得':''}}{{item.money | changMoney}}元
                        </div>
                        <div class="history-item-time">
                           {{item.create_time | changTimeType}}
                        </div>
                    </div>
                    <div class="history-item-right" :class="item.reward===2?'':'history-item-right-black'">
                       {{item.reward===2?'+':'-'}}{{item.money | changMoney}}
                    </div>
                </div> 
                <!-- 游戏币列表 -->
                <div class="history-item" v-else>
                    <div class="history-item-left">
                        <div class="history-item-title">
                           {{item.type | gameCoinChangeType}}游戏币{{item.game_coin_number | changMoney}}个
                        </div>
                        <div class="history-item-time">
                           {{item.create_time | changTimeType}}
                        </div>
                    </div>
                    <div class="history-item-right" :class="item.reward===2?'':'history-item-right-black'">
                       {{item.reward===2?'+':'-'}}{{item.game_coin_number | changMoney}}
                    </div>
                </div> 
            </div>
            <div class="last-tips" v-if="isShowLastTips && historyList.length>4">
                到底了
            </div>
            <div class="blank-tips" v-if="historyList.length<1">
                <img src="@/assets/index/exchange/exchange_history_blank.svg">
                <div>
                    暂无数据
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    
    export default {
        components: {
            TopNav
        },
        props:{
            listType:Array,
            currentListType:Number,
            historyList:Array,
            isShowLastTips:Boolean
        },
        data() {
            return {
                
                
            }
        },
        created() {
        },
        methods: {
            getCurrentTypeList(i) {
                this.$emit('getCurrentTypeList', i)
            }
        }
    }
</script>

<style scoped>
    .history-box {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .list-type-title-box {
        width: 100%;
        padding: 0 7px 12px 7px;
        margin-top: -6px;
        border-bottom: 1px solid #13C6CE;
        position: relative;
    }
    .list-type-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .list-type-title-item {
        width: 49%;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
    .select-line {
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 3px;
        background: #13C6CE;
        transition: 0.3s;
    }
    .list-box {
        width: 100%;
        padding-top: 20px;
        flex: 1;
        overflow-y: scroll;
    }
    .history-item-box {
        padding-bottom: 14px;
    }
    .history-item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 69px;
        background: #F4F9FA;
        border-radius: 6px 6px 6px 6px;
    }
    .history-item-left {
        padding-right: 5px;
        overflow: hidden;
        flex: 1;
    }
    .history-item-right {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FCBD2F;
    }
    
    .history-item-right-black {
        color: #000000;
    }
    
    .history-item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .history-item-time {
        padding-top: 5px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .last-tips {
       font-size: 14px;
       font-family: PingFang SC-Regular, PingFang SC;
       font-weight: 400;
       color: #999999; 
       text-align: center;
    }
    .blank-tips {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .blank-tips>img {
        width: 100%;
    }
    .blank-tips>div {
        padding-top: 21px;
        font-size: 15px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
    }
</style>